// Colors
$primary: #e0804e;
$secondary: #4eaee0;
$tertiary: #bae0f3;
$black: black;
$white: white;

$border-style: 4px dashed $primary;

// Container viewport width
$container-vw: 80vw;

$space-top: 20px;
$space-left: 20px;
$space-right: 20px;
$space-bottom: 20px;

$button-paddings-left-right: 48px;
$button-paddings-top-bottom: 48px;