@import './variables.scss';
@import './utils.scss';
@import './mixins.scss';

* {
  font-family: 'Nunito', sans-serif;
}

body {
  background-color: $white;
}

.container {
  @include flex-standarts;
  flex-direction: column;
  max-width: $container-vw;
  margin: 0 auto;
}

.button {
  background-color: $white;
  width: 100%;
  font-size: 2rem;
  padding: $button-paddings-top-bottom $button-paddings-left-right;
  color: $primary;
  border: 4px solid $primary;
  position: relative;
  cursor: pointer;
  &:hover {
    &::before {
      transition: 0.6s all ease;
      bottom: 10px;
      left: 10px;
      border-color: $primary;
    }
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    left: 0px;
    border: $border-style;
    border-color: transparent;
    z-index: -2;
  }
}

.copy-link-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.scroll-view {
  overflow: auto;
  padding: 20px;
  min-height: 20vh;
  max-height: 50vh;
  > div {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

#copyIndicator {
  margin-left: 10px;
}
